import React, { useState, useEffect } from "react";
import "./Input.css";
import Text from "../Text/Text";
// import Text from "../../ions/Text/Text";
// import Icon from "../../ions/Icon/Icon";

export interface InputProps {
  type: string;
  label?: string;
  placeholder: string;
  error?: string;
  readonly?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  classNameLabel?: string;
  initialValue?: string;
  value?: string;
}

const Input: React.FC<InputProps> = ({
  type,
  label,
  placeholder,
  readonly,
  onChange,
  error,
  classNameLabel,
  initialValue,
  value,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    // Update the state when the initialValue changes
    if (initialValue !== undefined) {
      setInputValue(initialValue);
    }
    if (value) {
      setInputValue(value);
    }
  }, [initialValue, value]); // Dependency array includes initialValue to track its changes

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;
    if (type === "number") {
      newValue = newValue.replace(/[^0-9]/g, "");
    }
    setInputValue(newValue); // Update the internal state
    onChange &&
      onChange({ ...event, target: { ...event.target, value: newValue } });
  };

  return (
    <div className={`input-container ${error ? "input-error" : ""}`}>
      {label && (
        <Text
          type="div"
          fontWeight="bold"
          fontSize="12px"
          color="#00000073"
          className={`block ${classNameLabel}`}
        >
          {label}
        </Text>
      )}
      <div style={{ position: "relative" }}>
        <input
          type={showPassword && type === "password" ? "text" : type}
          value={inputValue}
          className={`input-element ${error ? "input-element-error" : ""}`}
          placeholder={placeholder}
          readOnly={readonly}
          onChange={handleChange}
        />
        {error && (
          <div className="error-icon flex items-center">
            <span className="error-message ml-2 text-red-500 text-sm">
              {error}
            </span>
          </div>
        )}
        {type === "password" && (
          <button
            type="button"
            className="toggle-password"
            onClick={togglePasswordVisibility}
          >
       
          </button>
        )}
      </div>
    </div>
  );
};

export default Input;
