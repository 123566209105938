import React from "react";
import "./Text.css";

type TextProps = {
  type: "h1" | "h2" | "h3" | "h4" | "h5" | "span" | "div";
  fontWeight?: string;
  fontFamily?: string;
  fontSize?: string;
  color?: string;
  className?: string;
  children: React.ReactNode;
};

const Text: React.FC<TextProps> = ({
  type,
  fontSize,
  color,
  fontWeight,
  fontFamily,
  className,
  children,
}) => {
  const Tag = type as keyof JSX.IntrinsicElements;
  const style = {
    fontSize: fontSize,
    fontFamily: fontFamily,
    color: color,
    fontWeight: fontWeight,
  };
  return (
    <Tag style={style} className={className}>
      {children}
    </Tag>
  );
};

export default Text;
