import React from "react";
import CheckboxWithLabel from "../CheckboxWithLabel/CheckboxWithLabel";
import FormButtons from "../FormButtons/FormButtons";

export interface IOption<T> {
  label: string;
  value: T;
}

interface Props<T> {
  options: IOption<T>[];
  defaultSelected?: IOption<T>[];
  onSubmit: (data: IOption<T>[]) => void;
  onClear: () => void;
  searchable?: boolean;
  noOptionsMessage?: string;
  singleAndNoButtons?: boolean;
  maxHeight?: string; // Prefer doing that using pixels. Example: "300px"
}

function FormFilterOptions<T>({
  options,
  defaultSelected = [],
  onClear = () => {},
  onSubmit = () => {},
  maxHeight = "auto",
  noOptionsMessage = "Não há opções para mostrar",
  singleAndNoButtons = false,
}: Props<T>) {
  const [selected, setSelected] = React.useState(defaultSelected);
  const [rerenderKey, setRerenderKey] = React.useState<number | string>(0);

  return (
    <div>
      <div className="flex flex-col gap-2 overflow-auto" style={{ maxHeight }}>

        {options.length > 0 && options.map((it, i) => {
          const isChecked = selected.some((item) => item.label === it.label);
               return singleAndNoButtons ? <button
                   onClick={() => {
                       setSelected([options[i]])
                       onSubmit([options[i]])
                   }}
                   data-testid={"checkbox-"+i}
                   className={["py-2 text-left px-3 rounded border-lg focus:outline-none hover:cursor-pointer hover:bg-zinc-50",
                       selected[0]?.label === it.label ? "!bg-zinc-100" : ""].join(" ")}>{it.label}</button> : <CheckboxWithLabel
                key={i + "" + rerenderKey} // Add rerenderKey to trigger re-render
                label={it.label}
                testId={"checkbox-" + i}
                defaultChecked={isChecked}
                onChange={(it) => {
                    if (it) {
                        setSelected([...selected, options[i]]);
                    } else {
                        setSelected(
                            selected.filter((v) => v.label !== options[i].label),
                        );
                    }
                }} /> })
        }
          {options.length <= 0 && <p className={"text-center text-zinc-400 py-3"}>{noOptionsMessage}</p>}
      </div>
        { !singleAndNoButtons && <FormButtons
            topDivider={true}
            submitText="Ok"
            onClear={() => {
                setSelected([]);
                setRerenderKey((prevKey) => prevKey + "a"); // Trigger re-render
                onClear();
            }}
            onSubmit={() => onSubmit(selected)}
        />}

    </div>
  );
}
export default FormFilterOptions;
