import React, { forwardRef, useState } from "react";
import { FaChevronUp } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";


import "./FilterSelect.css";

export interface Props {
  title: string;
  onClick?: () => void;
  onClear?: () => void;
  opened?: boolean;
  selectedValues?: Array<string>;
  ref: any;
}

export const FilterSelect: React.ForwardRefExoticComponent<Props> = forwardRef(
  (
    { title, onClick, onClear, selectedValues = [], opened },
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const [selected, setSelected] = useState<boolean>(
      selectedValues.length === 0,
    );

    React.useEffect(
      () => setSelected(selectedValues.length > 0),
      [selectedValues],
    );



    return (
      <div
        ref={ref}
        onClick={onClick}
        className={"filter-dropdown " + (selected && "selected")}
      >
        <div className="filter-dropdown-text">
          {selected
            ? selectedValues.length > 1
              ? selectedValues[0] + " +" + (selectedValues.length - 1)
              : selectedValues[0]
            : title}
        </div>
        <div
          className="icon"
          style={{ transform: opened && !selected ? "rotate(180deg)" : "" }}
        >
          <div
            data-testid="filter-clear"
            onClick={(e) => {
              if (selected && onClear) {
                e.stopPropagation()
                onClear();
              }
            }}
          >{selected ? <IoClose size={16} /> : <FaChevronUp size={16}/>}

          </div>
        </div>
      </div>
    );
  },
);

export default FilterSelect;
