import React from "react";
import ModelCreate from "../../components/Model/ModelCreate";

const ModelAdd:React.FC = () => {

    return(
        <div>
            <ModelCreate />
        </div>
    );
}

export default ModelAdd;