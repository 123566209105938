import React from "react";
import ProfileList from "../../components/Profile/ProfileList";

const Profiles:React.FC = () => {

    return(
        <div>
            <ProfileList />
        </div>
    );
}

export default Profiles;