// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tag {
    padding: 8px 12px;
    border-radius: 16px;
    cursor: pointer;
    display: inline-block;
    margin-right: 8px;
  }
  
  .tag.gray {
    background-color: #e0e0e0;
  }
  
  .tag.primary {
    background-color: #2196F3;
    color: white;
  }
  
  .tag.secondary {
    background-color: #FF9800;
    color: white;
  }
  
  .tag.status-approved {
    background-color: #FBE8EC;
    color: #DB1742;
  }
  
  .tag.disabled {
    background-color: #BDBDBD;
    cursor: not-allowed;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/InputChip/InputChip.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,eAAe;IACf,qBAAqB;IACrB,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,cAAc;EAChB;;EAEA;IACE,yBAAyB;IACzB,mBAAmB;EACrB","sourcesContent":[".tag {\n    padding: 8px 12px;\n    border-radius: 16px;\n    cursor: pointer;\n    display: inline-block;\n    margin-right: 8px;\n  }\n  \n  .tag.gray {\n    background-color: #e0e0e0;\n  }\n  \n  .tag.primary {\n    background-color: #2196F3;\n    color: white;\n  }\n  \n  .tag.secondary {\n    background-color: #FF9800;\n    color: white;\n  }\n  \n  .tag.status-approved {\n    background-color: #FBE8EC;\n    color: #DB1742;\n  }\n  \n  .tag.disabled {\n    background-color: #BDBDBD;\n    cursor: not-allowed;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
