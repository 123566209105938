// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span,
div,
h1,
h2,
h3,
h4,
h5 {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
`, "",{"version":3,"sources":["webpack://./src/components/Text/Text.css"],"names":[],"mappings":"AAAA;;;;;;;EAOE,UAAU;EACV,SAAS;EACT,sBAAsB;AACxB","sourcesContent":["span,\ndiv,\nh1,\nh2,\nh3,\nh4,\nh5 {\n  padding: 0;\n  margin: 0;\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
