// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container {
  position: relative;
  width: 100%;
}

.input-container input {
  position: relative;
  border: 1px solid #d9d9d9;
  padding: 10px;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  border-radius: 2px;
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #fff;
  outline: none;
}
.toggle-password {
  position: absolute;
  top: 0;
  right: 10px;
  height: 100%;
  border: 0;
  background-color: transparent;
  padding-top: 8px;

  outline: none;
  cursor: pointer;
}
.input-container input[readonly] {
  background-color: #e9e9e9;
  border: 1px solid #d9d9d9;
  cursor: not-allowed;
}
`, "",{"version":3,"sources":["webpack://./src/components/Input/Input.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,eAAe;EACf;oEACkE;EAClE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,sBAAsB;EACtB,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,MAAM;EACN,WAAW;EACX,YAAY;EACZ,SAAS;EACT,6BAA6B;EAC7B,gBAAgB;;EAEhB,aAAa;EACb,eAAe;AACjB;AACA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,mBAAmB;AACrB","sourcesContent":[".input-container {\n  position: relative;\n  width: 100%;\n}\n\n.input-container input {\n  position: relative;\n  border: 1px solid #d9d9d9;\n  padding: 10px;\n  font-size: 12px;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';\n  border-radius: 2px;\n  width: 100%;\n  height: 40px;\n  line-height: 40px;\n  background-color: #fff;\n  outline: none;\n}\n.toggle-password {\n  position: absolute;\n  top: 0;\n  right: 10px;\n  height: 100%;\n  border: 0;\n  background-color: transparent;\n  padding-top: 8px;\n\n  outline: none;\n  cursor: pointer;\n}\n.input-container input[readonly] {\n  background-color: #e9e9e9;\n  border: 1px solid #d9d9d9;\n  cursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
