import React from "react";
import "./Button.css";

export interface ButtonProps {
  type?: "button" | "submit";
  primary?: boolean;
  backgroundColor?: string;
  size?: "small" | "medium" | "large";
  label?: string;
  onClick?: (e: React.FormEvent) => void;
  icon?: string;
  color?: string;
  borderless?: boolean;
  testId?: string;
  onlyIcon?: boolean;
  disabled?: boolean;
  className?: string;
  borderRadius?: string;
}

const Button: React.FC<ButtonProps> = ({
  type,
  primary,
  backgroundColor,
  size,
  label,
  icon,
  testId,
  color,
  borderless,
  onlyIcon = false,
  disabled = false,
  className = "",
  borderRadius,
  ...props
}: ButtonProps) => {
  const mode = primary ? "button--primary" : "button--secondary";
  const padding = onlyIcon ? 12 : 0;

  const optionalClasses = [
    size ? `button--${size}` : "",
    backgroundColor ? "custom-background-color" : "",
    borderless ? "borderless" : "",
    className,
  ].join(" ");

  return (
    <button
      data-testid={testId}
      type={type}
      style={{
        backgroundColor: backgroundColor ? backgroundColor : "",
        borderRadius: borderRadius ? borderRadius : "",
      }}
      disabled={disabled}
      className={`button ${mode} ${optionalClasses}`}
      {...props}
    >
      {/* {onlyIcon && <Icon name={icon} size={24} fill={color} />} */}
     
        {label}
     </button>
  );
};

export default Button;
