// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/landing.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*, *::before, *::after{
  margin:0;
  padding:0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}
.app{
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  margin: 0;
}

.btn-chat{
  position: absolute;
  top: 89%;
  transform: translateY(-50%);
  right: 50px;
  background-color: rgb(220, 29, 70);
  border:transparent; 
  border-radius: 50%;
  width: 80px;
  height: 80px;
  cursor:pointer;

}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,QAAQ;EACR,SAAS;EACT,sBAAsB;AACxB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,yDAA8C;EAC9C,sBAAsB;EACtB,4BAA4B;EAC5B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,YAAY;EACZ,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,2BAA2B;EAC3B,WAAW;EACX,kCAAkC;EAClC,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,cAAc;;AAEhB","sourcesContent":["*, *::before, *::after{\n  margin:0;\n  padding:0;\n  box-sizing: border-box;\n}\nhtml {\n  font-size: 62.5%;\n}\n.app{\n  background-image: url('../public/landing.png');\n  background-size: cover;\n  background-repeat: no-repeat;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  width: 100vw;\n  margin: 0;\n}\n\n.btn-chat{\n  position: absolute;\n  top: 89%;\n  transform: translateY(-50%);\n  right: 50px;\n  background-color: rgb(220, 29, 70);\n  border:transparent; \n  border-radius: 50%;\n  width: 80px;\n  height: 80px;\n  cursor:pointer;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
