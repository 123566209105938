import React, {useState} from "react";
import '../../App.css';
import Chat from "../../components/Chat/Chat";

const Home = () => {
    const [isChatOpen, setIsChatOpen] = useState(false);

    const toggleChat = () => {
      setIsChatOpen(!isChatOpen);
    };

    return(
        <div className="app">
        {!isChatOpen && (
          <button onClick={toggleChat} className="btn-chat">
            <img src={`${process.env.PUBLIC_URL}/Icone-Assistente_Piscando1.gif`} alt="Open Chat" style={{ width: 60, height: 60 }} />
          </button>
        )}
  
        {isChatOpen && <Chat isChatOpen={isChatOpen} toggleChat={toggleChat} />}
      </div>
    );
}

export default Home;