import api from "../api";
import { IProfile, Model } from "../interface/IModel";

//Models
export const getModels = async () => {
  return await api.get('/models');
};

export const getModel = async (id: string) => {
  return await api.get(`/models/${id}`);
};

export const createModel = async (model: any) => {
  return await api.post('/models', model);
};

export const updateModel = async (id: string, model: Model) => {
  return await api.put(`/models/${id}`, model);
};

export const deleteModel = async (id: string) => {
  return await api.delete(`/models/${id}`);
};



//Profiles
export const getProfiles = async () => {
  return await api.get('/profiles');
};

export const createProfile = async (profile: any) => {
  return await api.post('/profiles', profile);
};

export const getProfile = async (id: string) => {
  return await api.get(`/profiles/${id}`);
};

export const updateProfile = async (id: string, profile: IProfile) => {
  return await api.put(`/profiles/${id}`, profile);
};

export const deleteProfile = async (id: string) => {
  return await api.delete(`/profiles/${id}`);
};