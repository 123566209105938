import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createModel, getProfiles } from '../../services';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import FilterDropdown from '../FilterDropdown/FilterDropdown';
import FormFilterOptions, { IOption } from '../FormFilterOptions/FormFilterOptions';

export interface IProfile {
  id: string;
  profile_name: string;
}

const ModelCreate: React.FC = () => {
  const navigate = useNavigate();
  const [filteredOptions, setFilteredOptions] = useState<IOption<IProfile>[]>([]);
  const [optionsAll, setOptionsAll] = useState<IOption<IProfile>[]>([]);
  const [close, setClose] = useState(false);
  const [model, setModel] = useState({
    model_name: '',
    model_description: '',
    purchase_link: '',
    video_link: '',
    date_initial: '',
    date_final: '',
    photo: '',
    recommended_for: [] as IOption<IProfile>[],
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setModel((prevModel) => ({
      ...prevModel,
      [name]: value,
    }));
  };

  const formatDateString = (dateString: string) => {
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
};

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const formattedModel = {
      model_name: model.model_name,
      model_description: model.model_description,
      purchase_link: model.purchase_link,
      video_link: model.video_link,
      date_initial: formatDateString(model.date_initial),
      date_final: formatDateString(model.date_final),
      photo: model.photo,
      recommended_for: model.recommended_for.map((it) => it.label).join(', ').toString(),
    };

    await createModel(formattedModel);

    navigate('/panel');
  };

  const fetchProfiles = async () => {
    try {
      const response = await getProfiles();
      const allProfiles = response.data.map((profile: IProfile) => ({
        label: profile.profile_name,
        value: profile,
      }));
      setOptionsAll(allProfiles);
    } catch (error) {
      console.error("Error fetching profiles:", error);
    }
  };

  useEffect(() => {
    fetchProfiles();
  }, []);

  return (
    <div className="main">
      <div className="header">
        <div className="container flex align-between">
          <div className="logo flex align-between">
            <a href="/panel" className="flex align-center">
              <h2>Assistente Virtual</h2>
              <img src={process.env.PUBLIC_URL + '/logo_avell.svg'} alt="logo" />
            </a>
          </div>
          <div className="search"></div>
          <div className="profile">
            <span>Avell</span>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container">
          <div className="section-header">
            <div className="breadcrumbs">
              <a href="">Configurações</a>
              <MdOutlineKeyboardArrowRight size={24} />
              <a href="/model/add" className="active">Cadastro de Modelo</a>
            </div>
          </div>
          <div className="form">
            <form onSubmit={handleSubmit}>
              <label htmlFor="model_name">
                <span>Nome do Modelo</span>
                <input name="model_name" placeholder="Model Name" value={model.model_name} onChange={handleChange} required />
              </label>
              <label htmlFor="model_description">
                <span>Descrição</span>
                <input name="model_description" placeholder="Model Description" value={model.model_description} onChange={handleChange} required />
              </label>
              
              <label htmlFor="date_initial">
                <span>Data Inicial</span>
                <input name="date_initial" type="date" placeholder="DD/MM/AAAA" value={model.date_initial} onChange={handleChange} required />
              </label>

              <label htmlFor="date_final">
                <span>Data Final</span>
                <input name="date_final"  type="date" placeholder="DD/MM/AAAA" value={model.date_final} onChange={handleChange} required />
              </label>
              
              <label htmlFor="video_link">
                <span>Link do Vídeo</span>
                <input name="video_link" placeholder="Link do Vídeo" value={model.video_link} onChange={handleChange} required />
              </label>

              <label htmlFor="purchase_link">
                <span>Link</span>
                <input name="purchase_link" placeholder="Purchase Link" value={model.purchase_link} onChange={handleChange} required />
              </label>
              
              <label htmlFor="photo">
                <span>Foto</span>
                <input name="photo" placeholder="Photo URL" value={model.photo} onChange={handleChange} required />
              </label>
              
              <label htmlFor="recommended_for">
                <span>Recomendado para</span>
                <FilterDropdown
                  onClear={() => {
                    setFilteredOptions([]);
                  }}
                  selectedValues={model.recommended_for.map((it) => it.label)}
                  popupWidth={"200px"}
                  title="Selecione um Perfil"
                  closeSubscriber={close}
                >
                  <FormFilterOptions<IProfile>
                    maxHeight="232px"
                    defaultSelected={model.recommended_for}
                    onSubmit={(selected: IOption<IProfile>[]) => {
                      setModel((prevModel) => ({
                        ...prevModel,
                        recommended_for: selected,
                      }));
                      setFilteredOptions(selected);
                      setClose(!close);
                    }}
                    onClear={() => {
                      setModel((prevModel) => ({
                        ...prevModel,
                        recommended_for: [],
                      }));
                      setFilteredOptions([]);
                      setClose(!close);
                    }}
                    options={optionsAll}
                  />
                </FilterDropdown>
              </label>
              <button type="submit" className="btn-add">Adicionar</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModelCreate;
