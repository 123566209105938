import React from "react";

interface Props {
  onChange: (value: boolean) => void;
  defaultChecked?: boolean;
  label: string;
  testId?: string;
}

const CheckboxWithLabel: React.FC<Props> = ({
  onChange = () => {},
  defaultChecked = false,
  label,
  testId = "checkbox",
}) => {
  const [checked, setChecked] = React.useState(defaultChecked);
  const [initialized, setInitialized] = React.useState(false);
  /** The event subscriber for the checked state */
  React.useEffect(() => {
    if (initialized) {
      onChange(checked);
    }
    setInitialized(true);
  }, [checked]);

  return (
    <div
      data-testid={testId}
      className={[
        "flex gap-3 cursor-pointer group select-none items-center ",
        checked ? "checked" : " ",
      ].join(" ")}
      onClick={() => setChecked(!checked)}
    >
      {/** The box of the checkbox */}
      <div
        className={[
          "w-6 h-6 border rounded",
          checked ? "bg-[#E0E0E0] border-primary-600" : "group-hover:bg-[#FBE8EC]",
        ].join(" ")}
      ></div>
      <label className="cursor-pointer">{label}</label>
    </div>
  );
};
export default CheckboxWithLabel;
