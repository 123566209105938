import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getModel, getProfiles, updateModel } from "../../services";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { IOption } from "../../interface/IModel";
import FilterDropdown from "../../components/FilterDropdown/FilterDropdown";
import FormFilterOptions from "../../components/FormFilterOptions/FormFilterOptions";

export interface IProfile {
    label: string;
    value: string;
}

const ModelEdit: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [filteredOptions, setFilteredOptions] = useState<IOption<IProfile>[]>([]);
    const [optionsAll, setOptionsAll] = useState<IOption<IProfile>[]>([]);
    const [close, setClose] = useState(false);
    const [model, setModel] = useState({
      model_name: '',
      model_description: '',
      video_link: '',
      purchase_link: '',
      date_initial: '',
      date_final: '',
      photo: '',
      recommended_for: [] as IOption<IProfile>[],
    });

    const fetchModel = async () => {
      try {
          const response = await getModel(id!);
          const formattedRecommendedFor = response.data.recommended_for.map((label: string) => ({
              label,
              value: response.data._id,
          }));
          const formattedData = {
              ...response.data,
              recommended_for: formattedRecommendedFor,
          };
          setModel(formattedData);
          setFilteredOptions(formattedRecommendedFor);
      } catch (error) {
          console.error("Error fetching model:", error);
      }
  };
  

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setModel((prevModel) => ({
        ...prevModel,
        [name]: value,
      }));
    };

    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formattedModel = {
          model_name: model.model_name,
          model_description: model.model_description,
          video_link: model.video_link,
          purchase_link: model.purchase_link,
          date_initial: model.date_initial,
          date_final: model.date_final,
          photo: model.photo,
          recommended_for: model.recommended_for.map((it) => it.label).join(', ').toString(),
        };

        try {
            if (formattedModel) {
                await updateModel(id!, formattedModel);
                navigate('/panel');
            }
        } catch (error) {
            console.error("Error updating model:", error);
        }
    };

    const fetchProfiles = async () => {
        try {
            const response = await getProfiles();
            const allProfiles = response.data.map((profile: any) => ({
                label: profile.profile_name,
                value: profile._id,
            }));
            setOptionsAll(allProfiles);
        } catch (error) {
            console.error("Error fetching profiles:", error);
        }
    };

    useEffect(() => {
        fetchModel();
    }, [id]);

    useEffect(() => {
        fetchProfiles();
    }, []);


    if (!model) return <div>Loading...</div>;
   

    return (
        <div className="main">
            <div className="header">
                <div className="container flex align-between">
                    <div className="logo flex align-between">
                        <a href="/panel" className="flex align-center">
                            <h2>Assistente Virtual</h2>
                            <img src={process.env.PUBLIC_URL + '/logo_avell.svg'} alt="Logo" />
                        </a>
                    </div>
                    <div className="search"></div>
                    <div className="profile">
                        <span>Avell</span>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container">
                    <div className="section-header">
                        <div className="breadcrumbs">
                            <a href="/configuracoes">Configurações</a>
                            <MdOutlineKeyboardArrowRight size={24} />
                            <a href={`/model/edit/${id}`} className="active">Editar Modelo</a>
                        </div>
                    </div>

                    <div className="form">
                        <form onSubmit={handleFormSubmit}>
                            <label htmlFor="model_name">
                                <span>Nome do Modelo</span>
                                <input
                                    name="model_name"
                                    placeholder="Model Name"
                                    value={model.model_name}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                            <label htmlFor="model_description">
                                <span>Descrição</span>
                                <input
                                    name="model_description"
                                    placeholder="Model Description"
                                    value={model.model_description}
                                    onChange={handleChange}
                                    required
                                />
                            </label>

                            <label htmlFor="date_initial">
                              <span>Data Inicial</span>
                              <input name="date_initial" type="text" placeholder="DD/MM/AAAA" value={model.date_initial} onChange={handleChange} required />
                            </label>

                            <label htmlFor="date_final">
                              <span>Data Final</span>
                              <input name="date_final"  type="text" placeholder="DD/MM/AAAA" value={model.date_final} onChange={handleChange} required />
                            </label>



                            <label htmlFor="purchase_link">
                                <span>Link</span>
                                <input
                                    name="purchase_link"
                                    placeholder="Purchase Link"
                                    value={model.purchase_link}
                                    onChange={handleChange}
                                    required
                                />
                            </label>

                            <label htmlFor="video_link">
                                <span>Link do Vídeo</span>
                                <input
                                    name="video_link"
                                    placeholder="Link do Vídeo"
                                    value={model.video_link}
                                    onChange={handleChange}
                                    required
                                />
                            </label>

                            <label htmlFor="photo">
                                <span>Foto</span>
                                <input
                                    name="photo"
                                    placeholder="Photo URL"
                                    value={model.photo}
                                    onChange={handleChange}
                                    required
                                />
                            </label>

                            <label htmlFor="recommended_for">
                            <span>Recomendado para</span>
                            <FilterDropdown
                              onClear={() => {
                                setFilteredOptions([]);
                              }}
                              selectedValues={filteredOptions.map((it) => it.label)}
                              popupWidth={"200px"}
                              title="Selecione um Perfil"
                              closeSubscriber={close}
                            >
                              <FormFilterOptions<IProfile>
                                maxHeight="232px"
                                defaultSelected={filteredOptions}
                                onSubmit={(selected: IOption<IProfile>[]) => {
                                  setModel((prevModel) => ({
                                    ...prevModel,
                                    recommended_for: selected,
                                  }));
                                  setFilteredOptions(selected);
                                  setClose(!close);
                                }}
                                onClear={() => {
                                  setModel((prevModel) => ({
                                    ...prevModel,
                                    recommended_for: [],
                                  }));
                                  setFilteredOptions([]);
                                  setClose(!close);
                                }}
                                options={optionsAll}
                              />
                            </FilterDropdown>
                            </label>
                            <button type="submit" className="btn-add">Salvar</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModelEdit;
