// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-buttons {
    display: flex;
    padding: 8px 0;
    margin-top: 16px;
}
.form-buttons.topDivider {
    border-top: 1px solid rgba(0,0,0,0.06)
}
.form-buttons div:first-child {
    flex: 1 1;
    display: flex;
    align-items: center;
}

`, "",{"version":3,"sources":["webpack://./src/components/FormButtons/FormButtons.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;IACd,gBAAgB;AACpB;AACA;IACI;AACJ;AACA;IACI,SAAO;IACP,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".form-buttons {\n    display: flex;\n    padding: 8px 0;\n    margin-top: 16px;\n}\n.form-buttons.topDivider {\n    border-top: 1px solid rgba(0,0,0,0.06)\n}\n.form-buttons div:first-child {\n    flex: 1;\n    display: flex;\n    align-items: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
