import React from "react";
import ModelList from "../../components/Model/ModalList";
    
const Models:React.FC = () => {

    return(
        <div>
            <ModelList />
        </div>
    );
}

export default Models;