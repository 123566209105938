import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";

import AppRoutes from './router/AppRoutes';
// import Main from './components/Main';

const App:React.FC = () => {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

export default App;
