// Badge.tsx
import React from "react";
import { badgeConfig } from "./constants";

/**
 * Props for the Badge component.
 * @typedef {Object} BadgeProps
 * @property {keyof typeof badgeConfig} label - The label key to determine badge style and text.
 */
export type BadgeProps = {
  label: keyof typeof badgeConfig;
};

/**
 * Badge component displaying a styled label based on the provided configuration.
 * @param {BadgeProps} props - Props for Badge component.
 * @param {keyof typeof badgeConfig} props.label - The label key to determine badge style and text.
 * @returns {JSX.Element} The rendered Badge component.
 */
const Badge: React.FC<BadgeProps> = ({ label }: BadgeProps): JSX.Element => {
  const { backgroundColor, color, label: labelText } = badgeConfig[label];

  return (
    <div
      className="flex flex-row justify-center items-center text-lg border rounded-md px-2 h-8 font-normal"
      style={{
        backgroundColor,
        color,
      }}
    >
      {labelText}
    </div>
  );
};

export default Badge;
