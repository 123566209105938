import React, { useEffect, useRef } from "react";
import FilterSelect from "../FilterSelect/FilterSelect";

export interface Props {
  title: string;
  children: React.ReactElement;
  popupWidth: string;
  onClear: () => void;
  error?: string;
  selectedValues: string[];
  closeSubscriber?: boolean;
}

export const FilterDropdown: React.FC<Props> = ({
  title,
  popupWidth,
  children,
  onClear = () => {},
  selectedValues = [],
    error,
  closeSubscriber = false,
}) => {
  const selectRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const [opened, setOpened] = React.useState<boolean>(false);

  useEffect(() => {
    setOpened(false);
  }, [closeSubscriber]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        popupRef.current &&
        !selectRef.current.contains(event.target as Node) &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setOpened(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
      <div style={{position: "relative"}}>
    <div>
      <FilterSelect
        ref={selectRef}
        title={title}
        selectedValues={selectedValues}
        onClear={() => { onClear() }}
        onClick={() => setOpened(!opened)}
        opened={opened}
      />

      {opened && (
        <div
          ref={popupRef}
          style={{ width: popupWidth }}
          className="filter-dropdown-open"
        >
          {children}
        </div>
      )}
    </div>
    
        {error && <p className="filter-dropdown-erro">{error}</p>}
      </div>
  );
};

export default FilterDropdown;
