import "./InputChip.css";

export interface Props {
  children?: React.ReactElement;
  disabled?: boolean;
  selected?: boolean;
  onClick?: () => void;
  type?: "primary" | "secondary" | "gray" | "none";
}

/**
 * O **Input chip** pode ser tanto informativo quanto ter ações de habilitado desabilitado
 * como um toggle, porém mais viável para espaços menores pelo seu tamanho reduzido.
 *
 * - Quando ele é informativo, ele apenas é um retângulo com um texto dentro.
 * - Quando ele é um toggle, ele pode ser clicado e mudar de cor.
 */
const InputChip: React.FC<Props> = ({
  children,
  disabled,
  onClick,
  selected,
  type = "gray",
}) => {
  return (
    <div
      onClick={onClick && !disabled ? onClick : () => {}} // Não pode clicar se estiver desabilitado
      className={
        "tag " +
        (selected ? "status-approved " : "") +
        (disabled ? "disabled " : "") +
        type
      }
    >
      {children}
    </div>
  );
};

export default InputChip;
