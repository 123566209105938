import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoSearch } from "react-icons/io5";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { FaLink } from "react-icons/fa6";
import { getModels, deleteModel } from '../../services';
import { Model } from '../../interface/IModel';
import "../../index.css";
import Menu from '../Menu';
import FilterDropdown from '../FilterDropdown/FilterDropdown';
import FormFilterPeriod from '../FormFilterPeriod/FormFilterPeriod';
import FormFilterOptions, { IOption } from '../FormFilterOptions/FormFilterOptions';
import { parse, isWithinInterval } from 'date-fns';
import Badge from '../Badge/Badge';

export enum StatusOptions {
    "ATIVO" = "Ativo",
    "EXPIRADO" = "Expirado",
}

export interface IBoard {
    id: string;
    status: StatusOptions;
}

export const formatDateString = (dateString: string) => {
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
};


const ModelList = () => {
    const navigate = useNavigate();
    const [models, setModels] = useState<Model[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedPeriod, setSelectedPeriod] = useState<number | undefined>();
    const [startPeriod, setStartPeriod] = useState<string | undefined>();
    const [endPeriod, setEndPeriod] = useState<string | undefined>();
    const [formattedPeriod, setFormattedPeriod] = useState<string>("");
    const [filteredOptions, setFilteredOptions] = useState<IOption<StatusOptions>[]>([]);


    const [close, setClose] = useState(false);

    const optionsSide = [
        { label: "Ativo", value: StatusOptions.ATIVO },
        { label: "Expirado", value: StatusOptions.EXPIRADO },
    ];

    useEffect(() => {
        fetchModels();
    }, []);

    useEffect(() => {
        console.log(filteredOptions)
    }, [filteredOptions])

    const fetchModels = async () => {
        const response = await getModels();
        setModels(response.data);
    };

    const handleEditForm = (_id: string) => {
        navigate(`/model/edit/${_id}`);
    };

    const handleAddForm = () => {
        navigate('/model/add');
    };

    const handleDelete = async (id: string) => {
        await deleteModel(id);
        fetchModels();
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const handleClearFilters = () => {
        setSelectedPeriod(undefined);
        setStartPeriod(undefined);
        setEndPeriod(undefined);
        setFormattedPeriod("");
    };


    const handleFilterSubmit = (start: string, end?: string) => {
        setStartPeriod(formatDateString(start));
        setEndPeriod(formatDateString(end as string));
        setFormattedPeriod(`${formatDateString(start)} a ${end ? formatDateString(end) : ''}`);
        setClose(!close);
    };

    const parseDate = (dateString: string): Date => {
        // Converte uma data no formato dd/MM/yyyy para um objeto Date
        return parse(dateString, 'dd/MM/yyyy', new Date());
    };
    
    
    const isWithinDateRange = (date: string, start: string, end: string): boolean => {
        const parsedDate = parseDate(date);
        const parsedStart = parseDate(start);
        const parsedEnd = parseDate(end);
        return !start || !end || isWithinInterval(parsedDate, { start: parsedStart, end: parsedEnd });
    };
    
    const filteredModels = models.filter((model) => {
        const matchesSearchTerm =
            model.model_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            model.model_description.toLowerCase().includes(searchTerm.toLowerCase());
    
        const matchesDateRange =
            !startPeriod || !endPeriod || 
            (isWithinDateRange(model.date_initial, startPeriod, endPeriod) &&
             isWithinDateRange(model.date_final, startPeriod, endPeriod));
    
        const matchesStatus = filteredOptions.length === 0 || 
            filteredOptions.some(option => option.value === model.status);
    
        return matchesSearchTerm && matchesDateRange && matchesStatus;
    });
   

    return (
        <div className="main">
            <div className="header">
                <div className="container flex align-between">
                    <div className="logo flex align-between">
                        <a href="/" className="flex align-center">
                            <h2>Assistente Virtual</h2>
                            <img src={process.env.PUBLIC_URL + '/logo_avell.svg'} />
                        </a>
                    </div>
                    <div className="profile">
                        <span>Admin</span>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="container">
                    <div className="flex gap-primary space-top-bottom">
                        <Menu />
                        <div className="content-container">
                            <div className="section-header">
                                <div className="breadcrumbs">
                                    <a href="" className="active">Lista de Modelos</a>
                                </div>
                                <button onClick={handleAddForm} className="btn-add">Adicionar</button>
                            </div>
                            <div className="filters">
                                <div className="search">
                                    <IoSearch size={24} />
                                    <input
                                        type="text"
                                        placeholder="O que você procura?"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                        className="input-search"
                                    />
                                </div>
                                <div className="date">
                                    <FilterDropdown
                                        onClear={handleClearFilters}
                                        selectedValues={formattedPeriod ? [formattedPeriod.toString()] : []}
                                        popupWidth={"380px"}
                                        title="Período"
                                        closeSubscriber={close}
                                    >
                                        <FormFilterPeriod
                                            onSubmit={handleFilterSubmit}
                                            selectedPeriod={selectedPeriod}
                                        />
                                    </FilterDropdown>
                                </div>
                                <div className="xx">
                                    <FilterDropdown
                                        onClear={() => {
                                            setFilteredOptions([]);
                                        }}
                                        selectedValues={filteredOptions.map((it) => it.label)}
                                        popupWidth={"200px"}
                                        title="Status"
                                        closeSubscriber={close}
                                    >
                                        <FormFilterOptions<StatusOptions>
                                            maxHeight="232px"
                                            defaultSelected={filteredOptions}
                                            onSubmit={(it) => {
                                                setFilteredOptions(it);
                                                setClose(!close);
                                            }}
                                            onClear={() => {
                                                setFilteredOptions([]);
                                                setClose(!close);
                                            }}
                                            options={optionsSide}
                                        />
                                    </FilterDropdown>
                                </div>
                            </div>



                                    
                            
                            <div className="flex flex-col p-2 text-xl my-6">
                                {filteredModels.length === 0 && (
                                    <div className="flex items-center bg-white p-3 rounded-lg mb-2 custom-shadow border">
                                        <div className="w-full text-center text-gray-500">Não possui nenhum modelo cadastrado.</div>
                                    </div>
                                )}
                                
                                {filteredModels.length > 0 && (
                                    <div className="flex font-bold gap-4 my-4  text-[#111111] p-4">
                                        <div className="w-2/3 overflow-hidden">Modelo</div>
                                        <div className="w-1/12">Link</div>
                                        <div className="w-2/3 overflow-hidden">Recomendado</div>
                                        <div className="w-1/2">Período de Recomendação</div>
                                        <div className="w-1/6 flex justify-center items-center">Status</div>
                                        <div className="w-1/6"></div>
                                    </div>
                                )}
                                
                                
                                {filteredModels.map((model) => (
                                    <React.Fragment key={model._id}>
                                        <div className="flex items-center gap-4 bg-white h-[40px] px-4 rounded-lg mb-4 border border-[#E4E4E4]">
                                            <div className="w-2/3 overflow-hidden whitespace-nowrap overflow-ellipsis">{model.model_name}</div>
                                            <div className="w-1/12">
                                                <a href={model.purchase_link} target="_blank"><FaLink size={16} /></a>
                                            </div>
                                            <div className="w-2/3 overflow-hidden whitespace-nowrap overflow-ellipsis">{model.recommended_for.join(', ')}</div>
                                            <div className="w-1/2">{model.date_initial} a {model.date_final}</div>
                                            <div className="w-1/6 flex justify-center items-center">
                                                {model.status === "Ativo" ? (
                                                    <Badge label="Ativo" />
                                                ) : (
                                                    <Badge label="Expirado" />
                                                )}
                                            </div>
                                            <div className="w-1/6 flex items-center justify-end space-x-2">
                                                <button onClick={() => handleEditForm(model._id as string)}>
                                                    <AiOutlineEdit size={16} color="#4F4F4F" />
                                                </button>
                                                <button onClick={() => handleDelete(model._id as string)}>
                                                <AiOutlineDelete size={16} color="#DB1842" />
                                            </button>
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                                                
                                                    



                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModelList;
