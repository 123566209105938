import React, { useState, useEffect } from "react";
import FormButtons from "../FormButtons/FormButtons";
import Input from "../Input/Input";
import InputChip from "../InputChip/InputChip";
import "./FormFilterPeriod.css";

export interface Props {
  onSubmit: (startPeriod: string, endPeriod?: string) => void;
  selectedPeriod: number | undefined;
}

const FormFilterPeriod: React.FC<Props> = ({ onSubmit, selectedPeriod }) => {
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const chipsOptions = [7, 15, 30, 90];
  const [selectedOption, setSelectedOption] = useState<number | undefined>(selectedPeriod);

  const handleChipClick = (option: number) => {
    setSelectedOption(option);
    const end = new Date();
    const start = new Date();
    start.setDate(end.getDate() - option);
    const formatDate = (date: Date) => date.toISOString().split('T')[0];
    setStartDate(formatDate(start));
    setEndDate(formatDate(end));
    // onSubmit(formatDate(start), formatDate(end));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (startDate && endDate) {
      onSubmit(startDate, endDate);
    }
  };

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedOption(undefined);
  };

  useEffect(() => {
    if (selectedPeriod) {
      handleChipClick(selectedPeriod);
    }
  }, [selectedPeriod]);

  return (
    <form onSubmit={handleSubmit}>
      <div>Últimos Períodos</div>
      <div className="chips">
        {chipsOptions.map((option) => (
          <InputChip
            key={option}
            selected={option === selectedOption}
            onClick={() => handleChipClick(option)}
          >
            <span>{option} dias</span>
          </InputChip>
        ))}
      </div>
      <div>Períodos Específicos</div>
      <div className="group-input-date">
        <div>
          <Input
            placeholder="DD/MM/AAAA"
            label="Data Inicial"
            type="date"
            value={startDate || ''}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div>
          <Input
            placeholder="DD/MM/AAAA"
            label="Data Final"
            type="date"
            value={endDate || ''}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
      </div>
      <FormButtons
        topDivider
        submitText="Filtrar"
        clearText="Limpar Filtros"
        onSubmit={handleSubmit}
        onClear={handleClear}
      />
    </form>
  );
};

export default FormFilterPeriod;
