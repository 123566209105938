import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getProfile, updateProfile } from '../../services';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

export interface IProfile {
  id: string;
  profile_name: string;
}

const ProfileEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [profile, setProfile] = useState<IProfile>({  
    id: '',
    profile_name: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const formattedProfile = {
      profile_name: profile.profile_name,
    };

    await updateProfile(id!, formattedProfile);
    navigate('/profile');
  };


  const fetchProfiles = async () => {
    try {
        const response = await getProfile(id!);
        setProfile(response.data)
    } catch (error) {
        console.error("Error fetching profiles:", error);
    }
};

    useEffect(() => {
        fetchProfiles();
    }, [id]);

    useEffect(() => {
        fetchProfiles();
    }, []);


  if (!profile) return <div>Loading...</div>;

  return (
    <div className="main">
      <div className="header">
        <div className="container flex align-between">
          <div className="logo flex align-between">
            <a href="/panel" className="flex align-center">
              <h2>Assistente Virtual</h2>
              <img src={process.env.PUBLIC_URL + '/logo_avell.svg'} alt="logo" />
            </a>
          </div>
          <div className="search"></div>
          <div className="profile">
            <span>Admin</span>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container">
          <div className="section-header">
            <div className="breadcrumbs">
              <a href="">Configurações</a>
              <MdOutlineKeyboardArrowRight size={24} />
              <a href="/profile/add" className="active">Cadastro de Profile</a>
            </div>
          </div>
          <div className="form">
            <form onSubmit={handleSubmit}>
              <label htmlFor="profile_name">
                <span>Nome do Perfil</span> 
                <input name="profile_name" placeholder="Nome do Perfil" value={profile.profile_name} onChange={handleChange} required />
              </label>
              <button type="submit" className="btn-add">Adicionar</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileEdit;
