// constants.ts
/**
 * Configuration for badge styles, including background color, text color, and label text.
 */
export const badgeConfig = {
  Ativo: {
    backgroundColor: "#D9F7BE",
    color: "#135200",
    label: "Ativo",
  },
  Expirado: {
    backgroundColor: "var(--color-yellow-300)",
    color: "var(--calendula-gold-color-900)",
    label: "Expirado",
  }
} as const;

export enum BadgeEnum {
  Ativo = "Ativo",
  Expirado = "Expirado",

}
