// FormButtons.tsx
import React from "react";
import "./FormButtons.css";
import Button from "../Button/Button";

export interface FormButtonProps {
  clearText?: string;
  submitText?: string;
  onClear?: () => void;
  onSubmit?: (e: React.FormEvent) => void;
  topDivider?: boolean;
}

const FormButtons: React.FC<FormButtonProps> = ({
  clearText = "Limpar",
  onClear,
  onSubmit,
  submitText = "Enviar",
  topDivider = false,
}) => {
  return (
    <div className={"form-buttons " + (topDivider && "topDivider")}>
      <div>
        <Button
          type="button"
          borderless
          onClick={onClear}
          label={clearText}
        />
      </div>
      <Button type="button" onClick={onSubmit} primary label={submitText} />{" "}
    </div>
  );
};

export default FormButtons;
