// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-dropdown{
    display: flex;
    gap: 8px;
    background-color: #FFF;
    align-items: center;
    padding: 8px 16px 8px 16px;
    border: 1px solid #E4E4E4;
    border-radius: 6px;
    width: 230px;
    height: 40px;
    font-weight: 600;
    cursor: pointer;
    color: #111;
}

.filter-dropdown > .icon{
    transition: all .2s ease-in-out;
}
.filter-dropdown.selected{
    background-color: #FBE8EC;
    color: #DB1742;
    border:1px solid #f6dbe1;
}
.filter-dropdown-text{
    font-size: 12px;
    text-wrap: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/components/FilterSelect/FilterSelect.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,QAAQ;IACR,sBAAsB;IACtB,mBAAmB;IACnB,0BAA0B;IAC1B,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,+BAA+B;AACnC;AACA;IACI,yBAAyB;IACzB,cAAc;IACd,wBAAwB;AAC5B;AACA;IACI,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".filter-dropdown{\n    display: flex;\n    gap: 8px;\n    background-color: #FFF;\n    align-items: center;\n    padding: 8px 16px 8px 16px;\n    border: 1px solid #E4E4E4;\n    border-radius: 6px;\n    width: 230px;\n    height: 40px;\n    font-weight: 600;\n    cursor: pointer;\n    color: #111;\n}\n\n.filter-dropdown > .icon{\n    transition: all .2s ease-in-out;\n}\n.filter-dropdown.selected{\n    background-color: #FBE8EC;\n    color: #DB1742;\n    border:1px solid #f6dbe1;\n}\n.filter-dropdown-text{\n    font-size: 12px;\n    text-wrap: nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
