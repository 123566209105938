// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .button {
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.button.borderless {
  border: 1px solid rgba(255, 255, 255, 0) !important;
  opacity: 0.5;
  box-shadow: none;
}

  
  /* primary */
.button--primary {
  color: #db1742;
  background-color: transparent;
  border: 1px solid #db1742;
  border-radius: 6px;
  padding: 5px 15px;
}
.button--primary:hover{
  color: #FFF;
  background-color: #db1742;
  border: 1px solid transparent;
}

.button--secondary {
  color: #111;
  font-size:12px;
  background-color: transparent;
  transition: fill 0.3s ease;
}


.button--small {
  font-size: 12px;
  padding: 0px 7px;
  gap: 10px;
  display: flex;
}

.button--medium {
  font-size: 14px;
  padding: 4px 15px;
  gap: 10px;
  display: flex;
}

.button--large {
  font-size: 16px;
  padding: 6.4px 15px;
  gap: 10px;
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.css"],"names":[],"mappings":"AAAA;;;;;;GAMG;;AAEH;EACE,mDAAmD;EACnD,YAAY;EACZ,gBAAgB;AAClB;;;EAGE,YAAY;AACd;EACE,cAAc;EACd,6BAA6B;EAC7B,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,WAAW;EACX,yBAAyB;EACzB,6BAA6B;AAC/B;;AAEA;EACE,WAAW;EACX,cAAc;EACd,6BAA6B;EAC7B,0BAA0B;AAC5B;;;AAGA;EACE,eAAe;EACf,gBAAgB;EAChB,SAAS;EACT,aAAa;AACf;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,SAAS;EACT,aAAa;AACf;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,SAAS;EACT,aAAa;AACf","sourcesContent":["/* .button {\n  border-radius: 2px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n} */\n\n.button.borderless {\n  border: 1px solid rgba(255, 255, 255, 0) !important;\n  opacity: 0.5;\n  box-shadow: none;\n}\n\n  \n  /* primary */\n.button--primary {\n  color: #db1742;\n  background-color: transparent;\n  border: 1px solid #db1742;\n  border-radius: 6px;\n  padding: 5px 15px;\n}\n.button--primary:hover{\n  color: #FFF;\n  background-color: #db1742;\n  border: 1px solid transparent;\n}\n\n.button--secondary {\n  color: #111;\n  font-size:12px;\n  background-color: transparent;\n  transition: fill 0.3s ease;\n}\n\n\n.button--small {\n  font-size: 12px;\n  padding: 0px 7px;\n  gap: 10px;\n  display: flex;\n}\n\n.button--medium {\n  font-size: 14px;\n  padding: 4px 15px;\n  gap: 10px;\n  display: flex;\n}\n\n.button--large {\n  font-size: 16px;\n  padding: 6.4px 15px;\n  gap: 10px;\n  display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
