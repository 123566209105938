import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { getProfiles, deleteProfile } from '../../services';
import { IProfile } from '../../interface/IModel';
import "../../index.css";
import Menu from '../Menu';


export const formatDateString = (dateString: string) => {
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
};

const ProfileList = () => {
    const navigate = useNavigate();
    const [profiles, setProfiles] = useState<IProfile[]>([]);
    
    useEffect(() => {
        fetchProfiles();
    }, []);


    const fetchProfiles = async () => {
        const response = await getProfiles();
        setProfiles(response.data);
    };
 

    const handleAddForm = () => {
        navigate('/profile/add');
    };

    const handleEditForm = (_id: string) => {
        navigate(`/profile/edit/${_id}`);
    };

    const handleDelete = async (id: string) => {
        await deleteProfile(id);
        fetchProfiles();
    };

    return (
        <div className="main">
            <div className="header">
                <div className="container flex align-between">
                    <div className="logo flex align-between">
                        <a href="/" className="flex align-center">
                            <h2>Assistente Virtual</h2>
                            <img src={process.env.PUBLIC_URL + '/logo_avell.svg'} />
                        </a>
                    </div>
                    <div className="profile">
                        <span>Admin</span>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="container">
                    <div className="flex gap-primary space-top-bottom">
                        <Menu />
                        <div className="content-container">
                            <div className="section-header">
                                <div className="breadcrumbs">
                                    <a href="" className="active">Lista de Perfis</a>
                                </div>
                                <button onClick={handleAddForm} className="btn-add">Adicionar</button>
                            </div>

                            <table>
                                <thead>
                                    <tr>
                                        <th>Nome do Perfil</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {profiles.map((profile: any) => (
                                        <tr key={profile._id}>
                                            <td>{profile.profile_name}</td>
                                            <td className="flex">
                                                <button onClick={() => handleEditForm(profile._id as string)} className="btn-edit"><AiOutlineEdit size={24} color="#DB1842" /></button>
                                                <button onClick={() => handleDelete(profile._id as string)} className="btn-del"><AiOutlineDelete size={24} /></button>
                                            </td>
                                        </tr>
                                    ))}
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileList;
