import React, { useState, useEffect, useRef } from 'react';
import { AiFillAudio } from 'react-icons/ai';
import { FaCircleNotch } from "react-icons/fa";
import { BsRecordCircle } from "react-icons/bs";
import { IoSend, IoPlayCircleOutline, IoPauseCircleOutline, IoClose } from 'react-icons/io5';

import axios from 'axios';

import './Chat.css';

interface Message {
  text: string;
  isUser: boolean;
  products: any;
}

interface ChatProps {
  isChatOpen: boolean;
  toggleChat: () => void;
}

const Chat: React.FC<ChatProps> = ({ isChatOpen, toggleChat }) => {
  const [recording, setRecording] = useState(false);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
  const [audioUrl, setAudioUrl] = useState<string>('');
  const [currentAudioIndex, setCurrentAudioIndex] = useState<number | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [messages, setMessages] = useState<Message[]>([{ text: 'Olá! Como posso ajudá-lo hoje? Está interessado em algum dos nossos produtos de alta performance? Temos uma variedade de opções disponíveis, desde notebooks gamers a equipamentos voltados para profissionais de diversas áreas. Todos os nossos produtos são de alta qualidade, com desempenho eficiente e design elegante. Vamos encontrar o produto perfeito para você!', products: [], isUser: false }]);
  const [inputValue, setInputValue] = useState<string>('');
  const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingAudio, setIsLoadingAudio] = useState<boolean>(false);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((userStream) => {
        setStream(userStream);
        const mediaRecorder = new MediaRecorder(userStream);
        mediaRecorderRef.current = mediaRecorder;
        mediaRecorder.ondataavailable = (e) => {
          if (e.data.size > 0) {
            setAudioChunks([e.data]);
          }
        };
      })
      .catch((error) => {
        console.error('Erro ao capturar áudio:', error);
      });
  }, []);

  useEffect(() => {

    if (audioChunks && !recording) {
      playRecordedAudio();
    }
    // eslint-disable-next-line
  }, [audioChunks, recording]);


  useEffect(() => {
    const audioElement = document.getElementById('audioElement') as HTMLAudioElement;
    if (audioElement && isPlaying) {
      audioElement.play();
    } else if (audioElement && !isPlaying && !audioElement.paused) {
      audioElement.pause();
    }

    audioElement.onended = () => setIsPlaying(false);
  }, [isPlaying, isLoading]);



  const handleToggleRecording = () => {
    if (recording) {
      mediaRecorderRef.current?.stop();
      setRecording(false);
    } else if (stream) {
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          setAudioChunks([e.data]);
        }
      };
      mediaRecorder.start();
      setRecording(true);
    }
  };

  const playRecordedAudio = async () => {
    if (audioChunks.length === 0) {
      return;
    }

    const blob = new Blob(audioChunks, { type: 'audio/wav' });
    const formData = new FormData();
    const filename = 'audio.' + blob.type.split('/')[1];
    formData.append('audio', blob, filename);

    if (!allowedFile(filename)) {
      alert('Invalid file format. Allowed formats: WAV, MP3');
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post('https://fitsolution.com.br/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const baseAudioUrl = 'https://fitsolution.com.br/api/' + response.data.file_path;
      setAudioUrl(baseAudioUrl);
      setInputValue(response.data.text_audio);
    } catch (error) {
      console.error('Erro ao enviar áudio:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const allowedFileExtensions = ['wav', 'mp3'];

  const allowedFile = (filename: string) => {
    const extension = filename.split('.').pop();
    return extension ? allowedFileExtensions.includes(extension.toLowerCase()) : false;
  };

  const getSpeak = async (msg: string, index: number) => {
    try {
      setCurrentAudioIndex(index);
      console.log(currentAudioIndex, index);

      if (currentAudioIndex !== index) {
        setIsLoadingAudio(true);
        // setAudioUrl('');

        const response = await axios.post('https://fitsolution.com.br/api/speak', { msg });
        const baseAudioUrl = 'https://fitsolution.com.br/api/' + (response.data.file_path ?? '');
        setAudioUrl(baseAudioUrl);
        setCurrentAudioIndex(index);
        setIsLoadingAudio(false);
        setIsPlaying(true);
      }
    } catch (error) {
      console.error('Erro ao enviar áudio:', error);
      setIsLoadingAudio(false);
      setIsPlaying(false);
    } finally {
      setIsLoadingAudio(false);
      setIsPlaying(true);
    }
  };

  const postQuestion = async (question: string) => {
    setAwaitingResponse(true);
    const messageHistory = messages.map(msg => ({
      type: msg.isUser ? 'user' : 'bot',
      content: msg.text,
    }));
  
    try {
      const response = await axios.post('https://fitsolution.com.br/api/answer_question', {
        question,
        messageHistory,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
        timeout: 60000, 
      });
      
      let htmlResponse = response.data.response.replaceAll('\n', '<br>');
      htmlResponse = htmlResponse.replaceAll('<br><br>', '<br>');
      htmlResponse = htmlResponse.replaceAll('</li><br>', '</li>');
      htmlResponse = htmlResponse.replaceAll('<ul><br>', '<ul>');
      setMessages(prevMessages => [...prevMessages, { text: htmlResponse, products: response.data.product, isUser: false }]);
      
    
    } catch (error) {
      console.error('Error posting question:', error);
      setMessages(prevMessages => [...prevMessages, { text: 'Desculpe, houve um erro ao processar sua pergunta.', products: [], isUser: false }]);
    } finally {
      setAwaitingResponse(false);
    }
  };
  

  const handleMessageSubmit = () => {
    // e.preventDefault();
    if (inputValue.trim() !== '') {
      const newUserMessage = { text: inputValue, products: [], isUser: true };
      setMessages([...messages, newUserMessage]);
      postQuestion(inputValue);
      setInputValue('');
    }
  };

  const handleTogglePause = () => {
    setIsPlaying(!isPlaying);

  };

  return (
    <>
      <div className='overlay'></div>
      <div className={`chat-container ${isChatOpen ? 'open' : 'closed'}`}>
        <div className="chat-header">
          <p style={{ flex: 1 }}>Assistente <b>Avell IA Tech</b></p>
          <button onClick={toggleChat} style={{ background: 'transparent', border: 'none', cursor: 'pointer', color: '#FFF' }}>
            <IoClose size={24} />
          </button>
        </div>
        {isChatOpen && (
          <div className="chat-messages">
            {messages.map((msg, index) => (
              <div key={index} className={`message-chat ${msg.isUser ? 'user' : 'assistant'}`}>
                
                <div className="message-container">
                  <div>
                    <span className="message-icon">
                      {msg.isUser ? 
                        <img src={process.env.PUBLIC_URL + '/icon_user.png'} alt="User" style={{ width: 40, height: 40 }} /> : 
                        <img src={process.env.PUBLIC_URL + '/icon_bot.png'} alt="Bot" style={{ width: 35, height: 35 }} />
                      }
                    </span>
                  </div>


                  <p>
                    <div dangerouslySetInnerHTML={{ __html: msg.text }}></div>
                    <button className="btn">
                      {currentAudioIndex === index && isPlaying ? (
                        <IoPauseCircleOutline size={24} color="#DC1D46" onClick={() => handleTogglePause()} />
                      ) : (
                        isLoadingAudio && currentAudioIndex === index ? (
                          <FaCircleNotch size={20} className="fa-spin" />
                        ) : (
                          <IoPlayCircleOutline size={24} color="#B4B4B4" onClick={() => getSpeak(msg.text, index)} />
                        )
                      )}
                    </button>
                    <audio id="audioElement" src={audioUrl} />
                  </p>
                </div>

                {msg.products.length > 0 &&(
                <div className="product-container">
                  <h3>Recomendações</h3>
                  <ul>
                  {msg.products.map((product: any, index: any) => (
                    <li key={index}>
                      <img src={`https://fitsolution.com.br/api/${product.image}`} alt={product.name} width={150} height={120} />
                      <span>{product.name}</span>
                      <a href={product.link} target="_blank" rel="noreferrer">Detalhe</a>
                    </li>
                  ))}
                  </ul>
                </div>
                )}
              </div>

            ))}
            
            {awaitingResponse && (
              <div className="message assistant">
                <div className="message-icon"><FaCircleNotch size={20} className="fa-spin" /></div>
                <p>...</p>
              </div>
            )}
          </div>
        )}

        {isChatOpen && (
          <div className="chat-form">
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Digite sua mensagem..."
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleMessageSubmit();
                }
              }}
            />
            {stream && mediaRecorderRef.current && (
              <button onClick={handleToggleRecording}>
                {isLoading ? (
                  <FaCircleNotch size={30} className="fa-spin" />
                ) : (
                  recording ? <BsRecordCircle size={30} color="#DC1D46" className="blink_me" /> : <AiFillAudio size={30} color="#B4B4B4" />
                )}
              </button>
            )}
            <button type="button" onClick={handleMessageSubmit}>
              <IoSend color="#B4B4B4" size={30} />
            </button>


          </div>
        )}


      </div>
    </>
  );
};

export default Chat;
