import React, { useState } from "react";

import { MdOutlineKeyboardArrowRight, MdOutlineKeyboardArrowLeft } from "react-icons/md";

import { FaLaptop, FaRegUser } from "react-icons/fa6";



const Menu = () => {
    const [isOpen, setIsOpen] = useState(true);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
      };
    
    return (
    <>
    <div className="menu-container">
        <nav className={`menu ${isOpen ? "open" : "closed"}`}>
            <ul>
                <li>
                    <a href="profile">
                        <div className="icon">
                            <FaRegUser size={24} />
                        </div> 
                        <span>Perfil</span>
                    </a>
                </li>
                <li>
                    <a href="panel">
                        <div className="icon">
                            <FaLaptop size={24} /> 
                        </div>
                        <span>Modelo</span>
                    </a>
                </li>
            </ul>   
        </nav>   
        <button className="menu-toggle" onClick={toggleMenu}>
            {isOpen ? <MdOutlineKeyboardArrowLeft size={24} /> : <MdOutlineKeyboardArrowRight size={24}/>}
        </button>
    </div>     
    </>
    ); 
}

export default Menu;