import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import Home from "../pages/Home";
import Models from "../pages/Models/ModelList";
import ModelAdd from "../pages/Models/ModelAdd";
import ModelEdit from "../pages/Models/ModelEdit";
import Profiles from "../pages/Profiles/ProfileList";
import ProfileCreate from "../components/Profile/ProfileCreate";
import ProfileEdit from "../pages/Profiles/ProfileEdit";

const Layout = () => {
    const currentPath = window.location.pathname;
    return (
        <> 
        {currentPath !== '/' && null}
        <Outlet />
        </>
    );
};

function NoMatch() {
    return (
      <div>
        <h1>Ops, página não encontrada!</h1>
      </div>
    );
  }

const AppRoutes: React.FC = () => {


  return (
    <Routes>
        <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="panel" element={<Models />} />
            <Route path="profile" element={<Profiles />} />
            <Route path="profile/add" element={<ProfileCreate />} />
            <Route path="profile/edit/:id" element={<ProfileEdit />} />

            <Route path="model/add" element={<ModelAdd />} />
            <Route path="model/edit/:id" element={<ModelEdit />} />
            <Route path="*" element={<NoMatch />} />
        </Route>
    </Routes>
  );
};

export default AppRoutes;
