// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chips {
    flex-direction: row;
    margin: 15px 0;
    display: flex;
    gap: 16px;
}

.mt-3 {
margin-top: 12px;
}

.group-input-date{
    display: flex;
    flex-direction: column;
    padding: 0 4px;
    margin: 10px 0;
    gap:20px;
}`, "",{"version":3,"sources":["webpack://./src/components/FormFilterPeriod/FormFilterPeriod.css"],"names":[],"mappings":"AAAA;IAEI,mBAAmB;IACnB,cAAc;IACd,aAAa;IACb,SAAS;AACb;;AAEA;AACA,gBAAgB;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;IACd,cAAc;IACd,QAAQ;AACZ","sourcesContent":[".chips {\n    display: flex;\n    flex-direction: row;\n    margin: 15px 0;\n    display: flex;\n    gap: 16px;\n}\n\n.mt-3 {\nmargin-top: 12px;\n}\n\n.group-input-date{\n    display: flex;\n    flex-direction: column;\n    padding: 0 4px;\n    margin: 10px 0;\n    gap:20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
